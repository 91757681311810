import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c00bce84"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "new-puzzle__container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.remaining)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.$t('new_puzzle_message')) + " ", 1),
        _createElementVNode("button", {
          class: "new-puzzle__button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.start && _ctx.start(...args)))
        }, _toDisplayString(_ctx.$t('new_puzzle_button')), 1)
      ]))
    : _createCommentVNode("", true)
}