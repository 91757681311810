import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpdateBar = _resolveComponent("UpdateBar")!
  const _component_NewPuzzle = _resolveComponent("NewPuzzle")!
  const _component_OnboardingTutorial = _resolveComponent("OnboardingTutorial")!
  const _component_GameBoard = _resolveComponent("GameBoard")!
  const _component_FooterOptions = _resolveComponent("FooterOptions")!
  const _component_StatisticsPopup = _resolveComponent("StatisticsPopup")!
  const _component_VictoryPopup = _resolveComponent("VictoryPopup")!
  const _component_SettingsPopup = _resolveComponent("SettingsPopup")!
  const _component_CreditsPopup = _resolveComponent("CreditsPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", {
      class: "app__header",
      onDblclick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.easterEgg && _ctx.easterEgg(...args)))
    }, " Huezzle ", 32),
    _createVNode(_component_UpdateBar),
    _createVNode(_component_NewPuzzle),
    _createVNode(_component_OnboardingTutorial, {
      board: _ctx.board,
      settings: _ctx.settings,
      "already-played": !!_ctx.score
    }, null, 8, ["board", "settings", "already-played"]),
    _createVNode(_component_GameBoard, {
      board: _ctx.board,
      settings: _ctx.settings,
      "already-played": !!_ctx.score,
      "show-hints": _ctx.showHints,
      onStart: _ctx.start
    }, null, 8, ["board", "settings", "already-played", "show-hints", "onStart"]),
    _createVNode(_component_FooterOptions, {
      "show-statistics-button": _ctx.board.isSolved,
      onOpenStatisticsPopup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openPopup = 'statistics')),
      "show-hints-button": !_ctx.board.isSolved && _ctx.showHintsButton && this.requestedHints < 3,
      onShowHints: _ctx.handleShowHints,
      hints: _ctx.requestedHints,
      "show-victory-button": !!_ctx.score,
      onOpenVictoryPopup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openPopup = 'victory')),
      onOpenSettingsPopup: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openPopup = 'settings'))
    }, null, 8, ["show-statistics-button", "show-hints-button", "onShowHints", "hints", "show-victory-button"]),
    _createVNode(_component_StatisticsPopup, {
      "is-open": _ctx.openPopup === 'statistics',
      onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openPopup = null)),
      scores: _ctx.scoreRepository.getAll()
    }, null, 8, ["is-open", "scores"]),
    _createVNode(_component_VictoryPopup, {
      "is-open": _ctx.score !== null && _ctx.openPopup === 'victory',
      score: _ctx.score,
      onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openPopup = null))
    }, null, 8, ["is-open", "score"]),
    _createVNode(_component_SettingsPopup, {
      "is-open": _ctx.openPopup === 'settings',
      onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openPopup = null)),
      onSave: _ctx.saveSettings,
      onCredits: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openPopup = 'credits')),
      onImportBackup: _ctx.importBackup,
      settings: _ctx.settings,
      export: _ctx.scoreRepository.export()
    }, null, 8, ["is-open", "onSave", "onImportBackup", "settings", "export"]),
    _createVNode(_component_CreditsPopup, {
      "is-open": _ctx.openPopup === 'credits',
      onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.openPopup = null))
    }, null, 8, ["is-open"])
  ], 64))
}