
import { defineComponent, PropType } from 'vue'
import { Board } from '@/entities/Board'
import { Cell } from '@/entities/Cell'
import { Mode, Settings } from '@/entities/Settings'
import { DateTime } from 'luxon'

export default defineComponent({
  data () {
    return {
      fromId: null as Cell['id'] | null,
      touchStart: null as DateTime | null,
      selected: false,
      ghostColor: null as Cell['color'] | null,
      ghostWidth: null as string | null,
      ghostHeight: null as string | null,
      ghostStartTop: null as string | null,
      ghostStartLeft: null as string | null,
      ghostTop: null as string | null,
      ghostLeft: null as string | null
    }
  },

  props: {
    board: {
      required: true,
      type: Board as PropType<Board>
    },
    settings: {
      required: true,
      type: Settings as PropType<Settings>
    },
    alreadyPlayed: {
      required: true,
      type: Boolean as PropType<boolean>
    },
    showHints: {
      default: false,
      type: Boolean as PropType<boolean>
    }
  },

  emits: [
    'start'
  ],

  computed: {
    isGhostActive (): boolean {
      return this.settings.getMode() !== Mode.touch &&
        this.ghostColor !== null &&
        this.ghostWidth !== null &&
        this.ghostHeight !== null &&
        this.ghostStartTop !== null &&
        this.ghostStartLeft !== null &&
        this.ghostTop !== null &&
        this.ghostLeft !== null &&
        (
          this.settings.getMode() === Mode.grab ||
          (DateTime.now().diff(this.touchStart ?? DateTime.now()).toMillis() ?? 0) > 200
        )
    }
  },

  methods: {
    shuffle () {
      if (
        this.board.isShuffled ||
        this.alreadyPlayed
      ) {
        return
      }

      this.board.shuffle()
      this.$emit('start')
    },

    reset () {
      this.fromId = null
      this.ghostColor = null
      this.ghostWidth = null
      this.ghostHeight = null
      this.ghostStartTop = null
      this.ghostStartLeft = null
      this.ghostTop = null
      this.ghostLeft = null
      this.selected = false
    },

    grab (event: MouseEvent | TouchEvent) {
      if (
        !this.board.isShuffled ||
        this.board.isSolved
      ) {
        return
      }

      const cell = document.elementFromPoint(
        'pageX' in event ? event.pageX : event.touches[0].pageX,
        'pageY' in event ? event.pageY : event.touches[0].pageY
      )

      if (!cell) {
        return
      }

      const cellId = cell.getAttribute('data-cell-id') ?? null

      if (
        this.selected &&
        this.fromId &&
        cellId
      ) {
        this.board.swap(this.fromId, cellId)
        this.reset()
        return
      }

      const rect = cell.getBoundingClientRect()

      this.ghostStartTop = `${rect.top}px`
      this.ghostStartLeft = `${rect.left}px`
      this.ghostWidth = `${rect.width}px`
      this.ghostHeight = `${rect.height}px`

      this.ghostTop = `${'pageY' in event ? event.pageY : event.touches[0].pageY}px`
      this.ghostLeft = `${'pageX' in event ? event.pageX : event.touches[0].pageX}px`
      this.fromId = cellId
      this.ghostColor = cell.getAttribute('data-cell-color') ?? null
      this.touchStart = DateTime.now()
    },

    drop (event: MouseEvent | TouchEvent) {
      if (
        !this.board.isShuffled ||
        this.board.isSolved ||
        this.fromId === null
      ) {
        return
      }

      const cellId = document.elementFromPoint(
        'pageX' in event ? event.pageX : event.changedTouches[0].pageX,
        'pageY' in event ? event.pageY : event.changedTouches[0].pageY
      )?.getAttribute('data-cell-id')

      if (!cellId) {
        this.reset()
        return
      }

      if (
        this.settings.getMode() !== Mode.grab &&
        this.fromId === cellId &&
        !this.selected &&
        this.touchStart &&
        (DateTime.now().diff(this.touchStart).toMillis() ?? Infinity) < 200
      ) {
        this.selected = true
        return
      }

      if (this.settings.getMode() === Mode.touch) {
        return
      }

      this.board.swap(this.fromId, cellId)
      this.reset()
    },

    over (event: MouseEvent | TouchEvent) {
      if (
        !this.board.isShuffled ||
        this.board.isSolved ||
        this.settings.getMode() === Mode.touch ||
        this.selected
      ) {
        return
      }

      this.ghostTop = `${'pageY' in event ? event.pageY : event.touches[0].pageY}px`
      this.ghostLeft = `${'pageX' in event ? event.pageX : event.touches[0].pageX}px`
    },

    isDraggable (cell: Cell): boolean {
      return !cell.isFixed &&
        this.board.isShuffled &&
        !this.board.isSolved &&
        this.settings.getMode() !== 'touch'
    },

    isTouchable (cell: Cell): boolean {
      return !cell.isFixed &&
        this.board.isShuffled &&
        !this.board.isSolved &&
        (
          this.settings.getMode() === 'touch' ||
          this.selected
        )
    }
  },

  mounted () {
    if (!navigator.maxTouchPoints) {
      document.addEventListener('mousedown', this.grab)
      document.addEventListener('mousemove', this.over)
      document.addEventListener('mouseup', this.drop)
    }

    document.addEventListener('touchstart', this.grab)
    document.addEventListener('touchmove', this.over)
    document.addEventListener('touchend', this.drop)
  }
})
