
import { defineComponent, PropType } from 'vue'
import { Board } from '@/entities/Board'
import { Settings } from '@/entities/Settings'

export default defineComponent({
  props: {
    board: {
      required: true,
      type: Board as PropType<Board>
    },
    settings: {
      required: true,
      type: Settings as PropType<Settings>
    },
    alreadyPlayed: {
      required: true,
      type: Boolean as PropType<boolean>
    }
  }
})
