
import { defineComponent, PropType } from 'vue'
import { Duration } from 'luxon'
import { Difficulty } from '@/services/DifficultyGenerator'

export default defineComponent({
  emits: [
    'close'
  ],

  props: {
    difficulty: {
      required: true,
      type: String as PropType<null | Difficulty>
    },
    bestMovements: {
      required: true,
      type: Number as PropType<null | number>
    },
    bestTime: {
      required: true,
      type: Object as PropType<null | Duration>
    },
    averageMovements: {
      required: true,
      type: Number as PropType<number>
    },
    averageTime: {
      required: true,
      type: Object as PropType<Duration>
    },
    totalMovements: {
      required: true,
      type: Number as PropType<number>
    },
    totalTime: {
      required: true,
      type: Object as PropType<Duration>
    },
    playedGames: {
      required: true,
      type: Number as PropType<number>
    },
    hints: {
      required: true,
      type: Number as PropType<number>
    }
  },

  data () {
    return {
      page: null as null | Difficulty
    }
  },

  computed: {
    header () {
      return this.$t(`difficulty.${this.difficulty ?? 'all'}`)
    }
  },

  methods: {
    durationToHumanShort (duration: Duration | null): string {
      if (!duration?.toMillis()) {
        return '—'
      }

      return duration.toFormat('m:ss')
    },

    durationToHumanLong (duration: Duration | null): string {
      const milliseconds = duration?.toMillis()

      if (!milliseconds) {
        return '—'
      }

      const second = 1_000
      const minute = second * 60
      const hour = minute * 60
      const day = hour * 24
      const week = day * 7
      const month = day * 30.44
      const year = day * 365.25

      if (milliseconds >= year) {
        return Duration.fromObject({ year: +(milliseconds / year).toFixed(1) }).toHuman()
      }

      if (milliseconds >= month) {
        return Duration.fromObject({ month: +(milliseconds / month).toFixed(1) }).toHuman()
      }

      if (milliseconds >= week) {
        return Duration.fromObject({ week: +(milliseconds / week).toFixed(1) }).toHuman()
      }

      if (milliseconds >= day) {
        return Duration.fromObject({ day: +(milliseconds / day).toFixed(1) }).toHuman()
      }

      if (milliseconds >= hour) {
        return Duration.fromObject({ hour: +(milliseconds / hour).toFixed(1) }).toHuman()
      }

      if (milliseconds >= minute) {
        return Duration.fromObject({ minute: +(milliseconds / minute).toFixed(1) }).toHuman()
      }

      return Duration.fromObject({ second: +(milliseconds / second).toFixed(1) }).toHuman()
    },

    integerToHuman (number: number | null): string {
      if (!number) {
        return '—'
      }

      return number.toLocaleString(undefined, {
        maximumFractionDigits: 0
      })
    },

    decimalToHuman (number: number): string {
      if (!number) {
        return '—'
      }

      return number.toLocaleString(undefined, {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1
      })
    }
  }
})
