import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-718a3f59"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "statistics-page__page" }
const _hoisted_2 = { class: "statistics-page__header" }
const _hoisted_3 = { class: "statistics-page__label" }
const _hoisted_4 = { class: "statistics-page__value" }
const _hoisted_5 = { class: "statistics-page__label" }
const _hoisted_6 = { class: "statistics-page__value" }
const _hoisted_7 = { class: "statistics-page__label" }
const _hoisted_8 = { class: "statistics-page__value" }
const _hoisted_9 = { class: "statistics-page__label" }
const _hoisted_10 = { class: "statistics-page__value" }
const _hoisted_11 = { class: "statistics-page__label" }
const _hoisted_12 = { class: "statistics-page__value" }
const _hoisted_13 = { class: "statistics-page__label" }
const _hoisted_14 = { class: "statistics-page__value" }
const _hoisted_15 = { class: "statistics-page__label" }
const _hoisted_16 = { class: "statistics-page__value" }
const _hoisted_17 = { class: "statistics-page__label" }
const _hoisted_18 = { class: "statistics-page__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.header), 1),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(_toDisplayString(_ctx.$t('statistics_best_movements')) + " ", 1),
      _createElementVNode("strong", _hoisted_4, _toDisplayString(_ctx.integerToHuman(_ctx.bestMovements)), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createTextVNode(_toDisplayString(_ctx.$t('statistics_best_time')) + " ", 1),
      _createElementVNode("strong", _hoisted_6, _toDisplayString(_ctx.durationToHumanShort(_ctx.bestTime)), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createTextVNode(_toDisplayString(_ctx.$t('statistics_average_movements')) + " ", 1),
      _createElementVNode("strong", _hoisted_8, _toDisplayString(_ctx.decimalToHuman(_ctx.averageMovements)), 1)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createTextVNode(_toDisplayString(_ctx.$t('statistics_average_time')) + " ", 1),
      _createElementVNode("strong", _hoisted_10, _toDisplayString(_ctx.durationToHumanShort(_ctx.averageTime)), 1)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createTextVNode(_toDisplayString(_ctx.$t('statistics_total_movements')) + " ", 1),
      _createElementVNode("strong", _hoisted_12, _toDisplayString(_ctx.integerToHuman(_ctx.totalMovements)), 1)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createTextVNode(_toDisplayString(_ctx.$t('statistics_total_time')) + " ", 1),
      _createElementVNode("strong", _hoisted_14, _toDisplayString(_ctx.durationToHumanLong(_ctx.totalTime)), 1)
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createTextVNode(_toDisplayString(_ctx.$t('statistics_played_games')) + " ", 1),
      _createElementVNode("strong", _hoisted_16, _toDisplayString(_ctx.integerToHuman(_ctx.playedGames)), 1)
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createTextVNode(_toDisplayString(_ctx.$t('statistics_requested_hints')) + " ", 1),
      _createElementVNode("strong", _hoisted_18, _toDisplayString(_ctx.integerToHuman(_ctx.hints)), 1)
    ])
  ]))
}