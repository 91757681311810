
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  emits: [
    'openVictoryPopup',
    'openStatisticsPopup',
    'openSettingsPopup',
    'showHints'
  ],

  props: {
    showStatisticsButton: {
      default: false,
      type: Boolean as PropType<boolean>
    },
    showHintsButton: {
      default: false,
      type: Boolean as PropType<boolean>
    },
    hints: {
      required: true,
      type: Number as PropType<number>
    },
    showVictoryButton: {
      default: false,
      type: Boolean as PropType<boolean>
    }
  }
})
