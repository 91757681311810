
import { defineComponent, PropType } from 'vue'
import { DateTime, Duration } from 'luxon'
import ShareButtons from '@/components/ShareButtons.vue'
import PopupContainer from '@/components/PopupContainer.vue'
import { Score } from '@/entities/Score'

export default defineComponent({
  components: {
    PopupContainer,
    ShareButtons
  },

  emits: [
    'close'
  ],

  props: {
    score: {
      default: null,
      type: Score as PropType<Score | null>
    },
    isOpen: {
      default: false,
      type: Boolean as PropType<boolean>
    }
  },

  data () {
    const target = DateTime.now()
      .plus({ days: 1 })
      .set({ hour: 0, minute: 0, second: 0 })

    return {
      loop: null as number | null,
      remaining: target.diff(DateTime.now()) as Duration | null,
      target
    }
  },

  mounted () {
    this.loop = setInterval(() => {
      this.remaining = this.target.diff(DateTime.now())

      if (this.remaining.as('seconds') <= 0) {
        this.remaining = null
        clearInterval(this.loop ?? undefined)
      }
    }, 1000)
  },

  beforeUnmount () {
    clearInterval(this.loop ?? undefined)
  }
})
