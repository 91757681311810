
import { defineComponent } from 'vue'
import { PropType } from 'vue/dist/vue'
import { DateTime } from 'luxon'
import { Score } from '@/entities/Score'

export default defineComponent({
  props: {
    score: {
      required: true,
      type: Score as PropType<Score>
    }
  },

  computed: {
    canShare (): boolean {
      return typeof navigator.share === 'function'
    },
    canCopyToClipboard (): boolean {
      return typeof navigator.clipboard?.writeText === 'function'
    },
    message (): string {
      const launchDate = DateTime.fromISO('2023-08-01')
      const number = Math.floor(DateTime.now().diff(launchDate).as('days')) + 1
      return this.$t('share_message', {
        number: number,
        time: this.score.time.toFormat('m:ss'),
        movements: this.score.movements,
        hints: this.score.hints
      })
    },
    url (): string {
      return 'https://huezzle.antonio.gg'
    },
    tweetUrl () {
      const params = new URLSearchParams({
        text: this.message.replace('Huezzle', '@Huezzle'),
        url: this.url
      })

      return new URL(`https://twitter.com/intent/tweet?${params}`)
    }
  },

  methods: {
    share () {
      if (this.canShare) {
        navigator.share({
          title: this.message,
          text: this.message,
          url: this.url
        })
      }
    },
    clipboard () {
      if (this.canCopyToClipboard) {
        navigator.clipboard.writeText(`${this.message}\n${this.url}`)
      }
    }
  }
})
