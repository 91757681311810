
import { defineComponent } from 'vue'
import { DateTime, Duration } from 'luxon'

export default defineComponent({
  data () {
    const target = DateTime.now()
      .plus({ days: 1 })
      .set({ hour: 0, minute: 0, second: 0 })

    return {
      loop: null as number | null,
      remaining: target.diff(DateTime.now()) as Duration | null,
      target
    }
  },

  methods: {
    start () {
      window.location.reload()
    }
  },

  mounted () {
    this.loop = setInterval(() => {
      this.remaining = this.target.diff(DateTime.now())

      if (this.remaining.as('seconds') <= 0) {
        this.remaining = null
        clearInterval(this.loop ?? undefined)
      }
    }, 60_000)
  },

  beforeUnmount () {
    clearInterval(this.loop ?? undefined)
  }
})
