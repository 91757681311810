import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.updateStatus.status !== _ctx.UpdateStatus.noUpdates)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([
      'update-bar__container',
      _ctx.updateStatus.status === _ctx.UpdateStatus.downloading && 'update-bar__container--downloading',
      _ctx.updateStatus.status === _ctx.UpdateStatus.ready && 'update-bar__container--ready'
    ])
      }, [
        (_ctx.updateStatus.status === _ctx.UpdateStatus.downloading)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('updates_downloading_message')), 1)
            ], 64))
          : (_ctx.updateStatus.status === _ctx.UpdateStatus.ready)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('updates_ready_message')) + " ", 1),
                _createElementVNode("button", {
                  class: "update-bar__button",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.apply && _ctx.apply(...args)))
                }, _toDisplayString(_ctx.$t('updates_ready_button')), 1)
              ], 64))
            : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}