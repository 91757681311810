
import { defineComponent } from 'vue'
import { UpdateStatus, updateStatus } from '@/registerServiceWorker'

export default defineComponent({
  computed: {
    UpdateStatus () {
      return UpdateStatus
    },

    updateStatus () {
      return updateStatus
    }
  },

  methods: {
    apply () {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName)
        })
      }).finally(() => {
        window.location.reload()
      })
    }
  }
})
