import { Animations, Mode, Theme } from '@/entities/Settings'
import { Difficulty } from '@/services/DifficultyGenerator'

export const es = {
  clipboard_button_label: 'Copiar al portapapeles',
  credits_developer: 'un juego desarrollado por',
  credits_family: 'y en especial a mi familia y amigos',
  credits_header: 'Huezzle',
  credits_libraries: 'Todas las personas que han desarrollado las bibliotecas y herramientas que me han ayudado a crear este juego',
  credits_sub_thanks: 'por apoyar el desarrollo, aportar ideas y ayudar probando el juego',
  credits_thanks: 'Agradecimientos',
  difficulty: {
    [Difficulty.easy]: 'Fácil',
    [Difficulty.hard]: 'Difícil',
    [Difficulty.normal]: 'Normal',
    all: 'Total'
  },
  footer_hints_button_label: 'Mostrar pista {hints}/3',
  footer_settings_button_label: 'Configuración',
  footer_statistics_button_label: 'Ver estadísticas',
  footer_victory_button_label: 'Ver resultado',
  meta_description: 'Un nuevo rompecabezas cada día, ordena las celdas de colores y compite contra tus amigos y familiares.',
  meta_title: 'Huezzle - Tu rompecabezas diario',
  new_puzzle_button: 'Vamos',
  new_puzzle_message: '¡Hay un nuevo Huezzle disponible!',
  popup_close: 'Cerrar',
  settings_animations_button: {
    [Animations.disabled]: 'Desactivadas',
    [Animations.fast]: 'Rápidas',
    [Animations.normal]: 'Normales',
    [Animations.slow]: 'Lentas'
  },
  settings_animations_label: 'Animaciones',
  settings_backup_export_button: 'Exportar',
  settings_backup_import_button: 'Importar',
  settings_backup_label: 'Respaldar estadísticas',
  settings_credits_button: 'Ver',
  settings_credits_label: 'Créditos',
  settings_header: 'Configuración',
  settings_language_auto: 'Automático (Español)',
  settings_language_label: 'Idioma',
  settings_language_name: 'Español',
  settings_mode_button: {
    [Mode.both]: 'Ambos',
    [Mode.grab]: 'Agarrar',
    [Mode.touch]: 'Tocar'
  },
  settings_mode_label: 'Modo de intercambio',
  settings_support_label: 'Apoyar al desarrollador',
  settings_theme_button: {
    [Theme.auto]: 'Automático',
    [Theme.dark]: 'Oscuro',
    [Theme.light]: 'Claro'
  },
  settings_theme_label: 'Tema',
  settings_tutorial_button: 'Reiniciar',
  settings_tutorial_label: 'Tutorial',
  share_button_label: 'Compartir',
  share_message: 'El Huezzle del día #{number}:\n🕑 Tiempo: {time}\n🔄 Movimientos: {movements}\n💡 Pistas: {hints}\n',
  statistics_average_movements: 'Media de movimientos',
  statistics_average_time: 'Tiempo medio',
  statistics_best_movements: 'Mejores movimientos',
  statistics_best_time: 'Mejor tiempo',
  statistics_header: 'Estadísticas',
  statistics_played_games: 'Partidas jugadas',
  statistics_requested_hints: 'Pistas',
  statistics_total_movements: 'Movimientos totales',
  statistics_total_time: 'Tiempo total',
  tutorial_grab_message: 'Arrastra una celda sobre otra para intercambiar sus posiciones',
  tutorial_sort_message: 'Ordena todas las celdas de nuevo para ganar',
  tutorial_start_message: 'Toca para comenzar',
  tutorial_touch_message: 'Toca una celda y luego otra para intercambiar sus posiciones',
  tweet_button_label: 'Publicar',
  twitter_tips_1: 'Sigue a',
  twitter_tips_2: 'en X para recibir consejos diarios y estar al tanto de todas las actualizaciones.',
  updates_downloading_message: 'Descargando actualización...',
  updates_ready_button: 'Aplicar',
  updates_ready_message: '¡Actualización lista!',
  victory_header: '¡Victoria!',
  victory_movements_label: 'Movimientos:',
  victory_next_huezzle_label: 'Siguiente Huezzle:',
  victory_time_label: 'Tiempo:'
}
