
import PopupContainer from '@/components/PopupContainer.vue'
import { defineComponent, PropType } from 'vue'
import { Animations, Mode, Settings, Theme } from '@/entities/Settings'
import { DateTime } from 'luxon'

export default defineComponent({
  components: {
    PopupContainer
  },

  emits: [
    'close',
    'save',
    'credits',
    'importBackup'
  ],

  props: {
    settings: {
      required: true,
      type: Settings as PropType<Settings>
    },
    isOpen: {
      default: false,
      type: Boolean as PropType<boolean>
    },
    export: {
      required: true,
      type: String as PropType<string>
    }
  },

  computed: {
    exportUrl () {
      return URL.createObjectURL(new Blob([this.export], { type: 'text/plain' }))
    },

    exportFileName () {
      const date = DateTime.now().toMillis()
      return `huezzle_save_${date}.hsg`.replaceAll(/[^a-z0-9.]/g, '_')
    }
  },

  methods: {
    switchLanguage () {
      const available = this.$i18n.availableLocales
      const current = this.$i18n.locale
      const next = available[available.findIndex(language => current === language) + 1] ?? null

      this.settings.setLanguage(next)
      this.$emit('save')
    },

    switchMode () {
      const available = [
        Mode.both,
        Mode.grab,
        Mode.touch
      ]
      const current = this.settings.getMode()
      const next = available[available.findIndex(mode => current === mode) + 1] ?? Mode.both

      this.settings.setMode(next)
      this.$emit('save')
    },

    switchTheme () {
      const available = [
        Theme.auto,
        Theme.dark,
        Theme.light
      ]
      const current = this.settings.getTheme()
      const next = available[available.findIndex(theme => current === theme) + 1] ?? Theme.auto

      this.settings.setTheme(next)
      this.$emit('save')
    },

    switchAnimations () {
      const available = [
        Animations.normal,
        Animations.slow,
        Animations.disabled,
        Animations.fast
      ]
      const current = this.settings.getAnimations()
      const next = available[available.findIndex(animations => current === animations) + 1] ?? Animations.normal

      this.settings.setAnimations(next)
      this.$emit('save')
    },

    resetTutorial () {
      this.settings.resetTutorial()
      this.$emit('save')
    },

    importBackup (event: Event) {
      const input = event.target as HTMLInputElement
      const file = input.files ? input.files[0] : null

      if (!file) {
        return
      }

      const reader = new FileReader()
      reader.onload = ({ target }: ProgressEvent<FileReader>) => {
        if (target) {
          this.$emit('importBackup', target.result)
        }
      }

      reader.readAsText(file)
    }
  }
})
