import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-132bc99e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "onboarding-tutorial__step"
}
const _hoisted_2 = {
  key: 1,
  class: "onboarding-tutorial__step"
}
const _hoisted_3 = {
  key: 2,
  class: "onboarding-tutorial__step"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.settings.showTutorial() && !_ctx.board.isShuffled && !_ctx.alreadyPlayed)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('tutorial_start_message')), 1))
    : (_ctx.settings.showTutorial() && _ctx.board.isShuffled && _ctx.board.movements === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.settings.getMode() === 'touch' ? 'tutorial_touch_message' : 'tutorial_grab_message')), 1))
      : (_ctx.settings.showTutorial() && _ctx.board.isShuffled && _ctx.board.movements === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('tutorial_sort_message')), 1))
        : _createCommentVNode("", true)
}