
import { defineComponent, PropType } from 'vue'
import { Score } from '@/entities/Score'
import { Difficulty } from '@/services/DifficultyGenerator'
import StatisticsPage from '@/components/StatisticsPage.vue'
import PopupContainer from '@/components/PopupContainer.vue'
import { Statistics } from '@/entities/Statistics'

export default defineComponent({
  components: {
    PopupContainer,
    StatisticsPage
  },

  emits: [
    'close'
  ],

  props: {
    scores: {
      required: true,
      type: Object as PropType<Record<string, Score>>
    },
    isOpen: {
      default: false,
      type: Boolean as PropType<boolean>
    }
  },

  computed: {
    Difficulty () {
      return Difficulty
    },

    all () {
      return new Statistics(Object.values(this.scores))
    },

    easy () {
      return new Statistics(Object.values(this.scores), Difficulty.easy)
    },

    normal () {
      return new Statistics(Object.values(this.scores), Difficulty.normal)
    },

    hard () {
      return new Statistics(Object.values(this.scores), Difficulty.hard)
    }
  },

  data () {
    return {
      page: null as null | Difficulty
    }
  }
})
