
import { defineComponent } from 'vue'
import { PropType } from 'vue/dist/vue'

export default defineComponent({
  emits: [
    'close'
  ],

  props: {
    isOpen: {
      default: false,
      type: Boolean as PropType<boolean>
    }
  }
})
