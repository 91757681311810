import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, TransitionGroup as _TransitionGroup, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3c87a4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-cell-id", "data-cell-color"]
const _hoisted_2 = {
  key: 0,
  class: "game-board__image",
  src: "/img/fixed.svg",
  alt: ""
}
const _hoisted_3 = {
  key: 1,
  class: "game-board__image",
  src: "/img/close.svg",
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref: "ghost",
      class: _normalizeClass([
      'game-board__ghost',
      _ctx.isGhostActive && 'game-board__ghost--active',
      _ctx.selected && 'game-board__ghost--selected',
    ]),
      style: _normalizeStyle({
      '--color': _ctx.ghostColor,
      '--width': _ctx.ghostWidth,
      '--height': _ctx.ghostHeight,
      '--startTop': _ctx.ghostStartTop,
      '--startLeft': _ctx.ghostStartLeft,
      '--top': _ctx.ghostTop,
      '--left': _ctx.ghostLeft,
    })
    }, null, 6),
    _createVNode(_TransitionGroup, {
      tag: "div",
      class: _normalizeClass([
      'game-board__board',
      !_ctx.board.isShuffled && !_ctx.alreadyPlayed && 'game-board__board--not-shuffled',
      _ctx.isGhostActive && 'game-board__board--grabbing'
    ]),
      onClick: _ctx.shuffle,
      name: "game-board__swap",
      style: _normalizeStyle({
      '--rowWidth': _ctx.board.rowLength
    })
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.board.cells, (cell, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: cell.id,
            "data-cell-id": cell.id,
            "data-cell-color": cell.color,
            class: _normalizeClass([
        'game-board__cell',
        cell.isFixed && 'game-board__cell--fixed',
        _ctx.isDraggable(cell) && 'game-board__cell--draggable',
        _ctx.isTouchable(cell) && 'game-board__cell--touchable',
        _ctx.fromId === cell.id && _ctx.isGhostActive && 'game-board__cell--grabbed',
        _ctx.isGhostActive && 'game-board__cell--grabbing'
      ]),
            style: _normalizeStyle({
        '--color': cell.color
      } as CSSStyleDeclaration)
          }, [
            _createVNode(_Transition, { name: "game-board__fade" }, {
              default: _withCtx(() => [
                (cell.isFixed)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_2))
                  : (_ctx.showHints && cell.color !== _ctx.board.colorsInitialState[index])
                    ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                    : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ], 14, _hoisted_1))
        }), 128))
      ]),
      _: 1
    }, 8, ["class", "onClick", "style"])
  ], 64))
}